/** @type {import('./types/harmony-config').IHarmonyConfig} */
const CONFIG = {
    title: '온누리쇼핑',
    description: '온누리상품권 온라인 가맹점 온누리쇼핑',
    domain: 'onnuri',
    testEmail: 'super@freedgrouptech.com',
    testPassword: 'asdf123',
    locales: ['ko'],
    defaultLocale: 'ko',
    defaultCurrency: 'KRW',
    backgroundColor: '#F7F9FA',
    primaryFont: 'noto',
    secondaryFont: 'poppins',
    marketplaceTemplate: ['tangible', 'intangible'],
    loginType: ['login'],
    isPwa: true,

    // Keep at bottom of file for pipeline build
    designTemplate: 'designB',
};

module.exports = CONFIG;
